import React from 'react';
import { Button ,Modal } from 'react-bootstrap';
import { deleteOrganizer,deleteSubscription } from "../../helper/service"
import { toast } from "react-toastify";
const pabbly_username = import.meta.env.VITE_PABBLY_USER;
const pabbly_password = import.meta.env.VITE_PABBLY_PASSWORD;
import axios from "axios";

const DeleteConfirm = (props) => {
  // const [show, setShow] = React.useState(true);
  const deletePlanFromPabbly=async()=>{
    const pab_config = {
      auth: {
        username: pabbly_username,
        password: pabbly_password
        },
    headers: { 'Content-Type': 'application/json' }
    }
    axios.delete(`https://payments.pabbly.com/api/v1/plans/${props.clickedRowPablyMonthlyId}`,pab_config).then((pablyMonthlyPlan)=>{
      console.log("pably monthly plan deleted",pablyMonthlyPlan)
    }).catch((err)=>{
      console.log("pably monthly plan deleted error",err)
    });
    axios.delete(`https://payments.pabbly.com/api/v1/plans/${props.clickedRowPablyYearlyId}`,pab_config).then((pablyYearlyPlan)=>{
      console.log("pably monthly plan deleted",pablyYearlyPlan)
    }).catch((err)=>{
      console.log("pably monthly plan deleted error",err)
    });
  }
  const deletePartnerFromPabbly=async(partnerPabId)=>{
    const pab_config = {
      auth: {
        username: pabbly_username,
        password: pabbly_password
        },
    headers: { 'Content-Type': 'application/json' }
    }
    axios.delete(`https://payments.pabbly.com/api/v1/customers/${partnerPabId}`,pab_config).then((pablyPartnerDeletdRes)=>{
      console.log("pably parter deleted",pablyPartnerDeletdRes)
    }).catch((err)=>{
      console.log("pably partner deleted error",err)
    });
   
  }
  const handleClose = () => { 
    props.modal();
  }
  const handleDelete = () => {
   if(props.method === "Subscription") {
    console.log("subscripton delete")
    deleteSubscription(props.id);
    deletePlanFromPabbly();
    props.updateDate();
    toast("subscripton Deleted");
   }else{
     console.log("orgnizer deleted")
     console.log("orgnizer id",props.id)
      deleteOrganizer(props.id);
      deletePartnerFromPabbly(props.pabblyPartnerId);
      props.updateDate();
      toast("orgnizer Deleted");
   }
    props.modal();
  }
  // const handleShow = () => setShow(true);

  return (
    <div>
     

      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {props.method}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this {props.method}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleDelete}>
           YES
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteConfirm;