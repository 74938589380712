import axios from "axios";

// Setting up the common default config for axios
const accessToken =  localStorage.getItem("token")
if (accessToken) axios.defaults.headers.common['Authorization'] = accessToken;
axios.defaults.headers.common['Content-Type'] = 'application/json';

let getBaseUrl = () =>{
  return import.meta.env.VITE_BACKEND_API_URL;
}

let updateUser = async(id) => {
  var accessToken =  localStorage.getItem("token")
let config = {
  
  headers: {
  'Content-Type': 'application/json',
  "Authorization" : accessToken
 }

}
 let url  = getBaseUrl();
 let body = {
  "firstName": "updated person"
 }
    await axios.put(`${url}/user/${id}`,body,config).then((res) => {
      console.log(res)
    }).catch((error) => console.log(error.message))
}

const updateAdmin = (id, data) => {
  return axios.put(`${getBaseUrl()}/subadmin/${id}`, data);
};

let deleteOrganizer = async(id) => {
  var accessToken =  localStorage.getItem("token")
  let url  = getBaseUrl();
  let config = {
  
  headers: {
  'Content-Type': 'application/json', 
  "Authorization" : accessToken
 }

}
  await axios.delete(`${url}/subadmin/${id}`,config).then((res) => {
    console.log(res)
  })
}

let deleteSubscription = async(id) => {
  var accessToken =  localStorage.getItem("token")
  let url  = getBaseUrl();
  let config = {
  
  headers: {
  'Content-Type': 'application/json', 
  "Authorization" : accessToken
 }

}
  await axios.delete(`${url}/sasubscription/${id}`,config).then((res) => {
    console.log("delete subs",res)
  })
}

export { updateUser, updateAdmin, deleteOrganizer, deleteSubscription, getBaseUrl }