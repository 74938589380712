import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { deleteOrganizer, updateUser } from "../../../helper/service";
import { ToastContainer, toast } from "react-toastify";
import DeleteConfirm from "../../modals/deleteConfirmation";
import ViewOrganizer from "../../modals/viewOrganizer";
import BSB from "bootstrap-switch-button-react";
const BootstrapSwitchButton = BSB?.default ? BSB.default : BSB;
// https://github.com/vitejs/vite/issues/2139#issuecomment-802981228

import SearchFilter from "./SearchFilter";

function GeneralCustomers() {
  const [data, setData] = useState([]);
  const [dataPaginate, setDataPaginate] = useState([]);
  const sort = 7;
  const activePag = useRef(0);
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const [showOrganizer, setOrganizer] = useState(false);
  const [organizer, viewOrganizer] = useState({});
  const [pabblyPartnerId, setPabblyPartnerId] = useState();

  const [searchFilter, setSearchFilter] = useState("");

  const showModal = () => {
    setShow(false);
  };

  let fetchData = async () => {
    var accessToken = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: accessToken,
      },
    };
    await axios.get(import.meta.env.VITE_BACKEND_API_URL + "/subadmin", config).then((res) => {
      if (res.data.result.validUser.length !== 0) {
        setData(res.data.result.validUser);
        let arr2 = [];
        for (let i = 0; i < 7; i++) {
          arr2.push(res.data.result.validUser[i]);
        }
        console.log(res.data.result.validUser, "plannnnnnnn");
        setDataPaginate(arr2);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //update
  const update = (row) => {
    //  updateUser(id);
    setOrganizer(true);
    viewOrganizer(row);
  };

  const closeOrganizer = () => {
    setOrganizer(false);
  };

  const deleteUser = (e, row) => {
    e.preventDefault();
    setPabblyPartnerId(row.pabblyUserId);
    setId(row._id);
    setShow(true);
  };

  const callBackend = async (row, data) => {
    var accessToken = localStorage.getItem("token");
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    await axios
      .put(import.meta.env.VITE_BACKEND_API_URL + `/subadmin/admin/${row._id}`, data, config)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleActive = (row) => {
    if (row.status === "Active") {
      organizer.status = "in-Active";
      callBackend(row, organizer);
      row.status = "in-Active";
    } else {
      organizer.status = "Active";
      callBackend(row, organizer);
      row.status = "Active";
    }
  };

  // Active pagginarion
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    const nextArr = data.slice(activePag.current * sort, (activePag.current + 1) * sort);
    setDataPaginate(nextArr);
  };

  const getProcessedData = () => {
    if (!data) return [];
    let processedData = data;
    if (searchFilter) {
      processedData = processedData.filter((item) => {
        return (
          item.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
          item.businessName.toLowerCase().includes(searchFilter.toLowerCase())
        );
      });
      return processedData;
    } else {
      return dataPaginate;
    }
  };

  return (
    <>
      {show ? (
        <DeleteConfirm
          modal={showModal}
          show={show}
          id={id}
          method={"Organizer"}
          updateDate={fetchData}
          pabblyPartnerId={pabblyPartnerId}
        />
      ) : (
        ""
      )}
      {showOrganizer ? (
        <ViewOrganizer modal={closeOrganizer} show={showOrganizer} organizer={organizer} />
      ) : (
        ""
      )}

      {/* <div className="d-sm-flex mb-lg-4 mb-2" style={{float:"right"}}>
       <Link to="/create-organizer"> <Button>New Admin</Button> </Link>
        </div> */}
      <Dropdown className="dropdown custom-dropdown mb-md-4 mb-2" style={{ display: "none" }}>
        <Dropdown.Toggle
          type="button"
          className="i-false btn btn-primary light d-flex align-items-center svg-btn"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.5 6.125H12.25C12.25 5.15987 11.4651 4.375 10.5 4.375H8.75C7.78487 4.375 7 5.15987 7 6.125H3.5C3.017 6.125 2.625 6.51612 2.625 7C2.625 7.48388 3.017 7.875 3.5 7.875H7C7 8.84013 7.78487 9.625 8.75 9.625H10.5C11.4651 9.625 12.25 8.84013 12.25 7.875H24.5C24.983 7.875 25.375 7.48388 25.375 7C25.375 6.51612 24.983 6.125 24.5 6.125ZM8.75 7.875V6.125H10.5L10.5009 6.9965C10.5009 6.99825 10.5 6.99913 10.5 7C10.5 7.00087 10.5009 7.00175 10.5009 7.0035V7.875H8.75Z"
              fill="#EA7A9A"
            />
            <path
              d="M24.5 13.125H19.25C19.25 12.1599 18.4651 11.375 17.5 11.375H15.75C14.7849 11.375 14 12.1599 14 13.125H3.5C3.017 13.125 2.625 13.5161 2.625 14C2.625 14.4839 3.017 14.875 3.5 14.875H14C14 15.8401 14.7849 16.625 15.75 16.625H17.5C18.4651 16.625 19.25 15.8401 19.25 14.875H24.5C24.983 14.875 25.375 14.4839 25.375 14C25.375 13.5161 24.983 13.125 24.5 13.125ZM15.75 14.875V13.125H17.5L17.5009 13.9965C17.5009 13.9983 17.5 13.9991 17.5 14C17.5 14.0009 17.5009 14.0017 17.5009 14.0035V14.875H15.75Z"
              fill="#EA7A9A"
            />
            <path
              d="M24.5 20.125H12.25C12.25 19.1599 11.4651 18.375 10.5 18.375H8.75C7.78487 18.375 7 19.1599 7 20.125H3.5C3.017 20.125 2.625 20.5161 2.625 21C2.625 21.4839 3.017 21.875 3.5 21.875H7C7 22.8401 7.78487 23.625 8.75 23.625H10.5C11.4651 23.625 12.25 22.8401 12.25 21.875H24.5C24.983 21.875 25.375 21.4839 25.375 21C25.375 20.5161 24.983 20.125 24.5 20.125ZM8.75 21.875V20.125H10.5L10.5009 20.9965C10.5009 20.9983 10.5 20.9991 10.5 21C10.5 21.0009 10.5009 21.0017 10.5009 21.0035V21.875H8.75Z"
              fill="#EA7A9A"
            />
          </svg>
          <span className="fs-16 ml-3">Filter</span>
          <i className="fa fa-angle-down scale5 ml-3" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
          <Dropdown.Item className="dropdown-item" to="#">
            2020
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item" to="#">
            2019
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item" to="#">
            2018
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item" to="#">
            2017
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item" to="#">
            2016
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <SearchFilter setSearchFilter={setSearchFilter} searchFilter={searchFilter} />

      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive rounded card-table">
            <div id="generalCustomer" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Customer ID: activate to sort column descending"
                      style={{ width: 100 }}
                    >
                      Business Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Join Date: activate to sort column ascending"
                      style={{ width: 166 }}
                    >
                      Primary User
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: 126 }}
                    >
                      Plan
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Location: activate to sort column ascending"
                      style={{ width: 184 }}
                    >
                      Email
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Total Spent: activate to sort column ascending"
                      style={{ width: 90 }}
                    >
                      Signed Up on
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Last Order: activate to sort column ascending"
                      style={{ width: 85 }}
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label=": activate to sort column ascending"
                      style={{ width: 26 }}
                    />
                  </tr>
                </thead>
                {data.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {getProcessedData()
                      .map((row) => {
                        return (
                          <tr
                            className="alert alert-dismissible border-0 odd"
                            role="row"
                            key={row._id}
                          >
                            <td className="sorting_1">{row.businessName}</td>
                            <td>{row.firstName}</td>
                            <td>{row.subscriptionId?.at(0)?.subsId?.planName || "-"}</td>
                            <td>{row.email}</td>
                            <td>{row.createdAt.slice(0, 10)}</td>
                            <td>
                              <BootstrapSwitchButton
                                onlabel=" "
                                offlabel=" "
                                checked={row.status === "Active" ? true : false}
                                onChange={() => handleActive(row)}
                              />
                            </td>
                            <td>
                              <Dropdown className="dropdown">
                                <Dropdown.Toggle
                                  variant=""
                                  className="i-false"
                                  to="#"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  <Dropdown.Item className="dropdown-item" to="#">
                                    <span
                                      key={row._id}
                                      onClick={() => {
                                        update(row);
                                      }}
                                    >
                                      <i className="las la-check-circle text-success mr-3 scale5" />
                                      View Business Admin
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    to="#"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    className="dropdown-item"
                                  >
                                    <span
                                      key={row._id}
                                      onClick={(e) => {
                                        deleteUser(e, row);
                                      }}
                                    >
                                      <i className="las la-times-circle text-danger mr-3 scale5" />
                                      Delete
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
              <ToastContainer />
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div
                  className="dataTables_info"
                  id="example5_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers" id="example5_paginate">
                  <button
                    className="paginate_button previous disabled"
                    // to="/admin"
                    onClick={() => activePag.current > 0 && onClick(activePag.current - 1)}
                  >
                    Previous
                  </button>
                  {/* <span>
                    {paggination.map((number, i) => (
                      <button
                        key={i}
                        // to="admin"
                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                          } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </button>
                    ))}
                  </span> */}
                  <button
                    className="paginate_button next"
                    onClick={() =>
                      activePag.current + 1 < paggination.length && onClick(activePag.current + 1)
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralCustomers;
